import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import StyledBackgroundSection from "../components/bg-image"

const BooksIread = () => (
  <Layout>
    <SEO title="Books i read" />
   
      <div className="container">
          <h1>Book reviews</h1>
          <p>All books mentioned are books I've personally read. The opinions expressed are solely mine.</p>
          <h2>1. You don't know JS</h2>
      </div> 
  
  </Layout>
)

export default BooksIread 
